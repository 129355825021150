*{
    padding: 0;
    margin: 0;
    font-family: cursive;
}
$text-brown : $text-brown;

.container{
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 24px;
}
.fortune{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 70px;
    position: relative;
    h3{
        position: absolute;
        top: 9%;
        right: 0;
        color: $text-brown;
        font-weight: 900;
        font-size: 40px;
    }
    img{
        width: 415px;
        height: 415px;
        border-radius: 30px;
    }
}
.buyHere{
    color: #c5af90; 
    background-color: $text-brown;
    text-decoration: underline;
    padding: 0px 12px;
    font-size: 70px;
    font-weight: 900;
    border-radius: 10px;
    transform: translateY(105px);
}