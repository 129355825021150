* {
  padding: 0;
  margin: 0;
  font-family: cursive;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 24px;
}

.fortune {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 70px;
  position: relative;
}
.fortune h3 {
  position: absolute;
  top: 9%;
  right: 0;
  color: #544537;
  font-weight: 900;
  font-size: 40px;
}
.fortune img {
  width: 415px;
  height: 415px;
  border-radius: 30px;
}

.buyHere {
  color: #c5af90;
  background-color: #544537;
  text-decoration: underline;
  padding: 0px 12px;
  font-size: 70px;
  font-weight: 900;
  border-radius: 10px;
}

.textSelf {
  color: #544537;
  background-color: #8f7a68;
  text-decoration: underline;
  padding: 0px 12px;
  font-size: 70px;
  font-weight: 900;
  border-radius: 10px;
}/*# sourceMappingURL=style.css.map */